import format from "date-fns/format";


export default function FormatDate(date?: string, doNotUseYear?: boolean, doNotUseDay?: boolean): string {

    if (date === undefined) {
        return "Undefined";
    }

    var newDate = Date.parse(date);

    if (doNotUseYear) {
        return format(newDate, 'd MMMM');
    }
    if (doNotUseDay) {
        return format(newDate, 'MMMM yyyy');
    }
    else {
        return format(newDate, 'd MMM yyyy');
    }
}