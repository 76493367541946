import { TableReport } from "@impulso/common/components/report/TableReport";
import { Delivery } from "src/api/ReportApi";
import { useMemo } from "react";
import { TableReportColumn } from "@impulso/common/components/report/DataTable";
import FormatDate from "@impulso/common/styling/FormatDate";
import { SimpleBadge } from "@impulso/common/components/Badge";
import { AsyncLinkDownload } from "@impulso/common/components/link";
import Download from "@impulso/common/Icons/Download";
import { downloadDeliveryBreakdown } from "src/api/DownloadApi";
import { OrganisationId } from "src/api/models/UserProfile";
import { useGlobalSecurity } from "src/common/security/UseGlobalSecurity";
import { DashFilter } from "src/pages/new/Dashboard";

interface DeliveriesProps {
    organisationId: OrganisationId,
    deliveries: Delivery[],
    filter: DashFilter,
    isLoading: boolean
}

export default function DeliveriesReport(props: DeliveriesProps) {
    const {accessToken} = useGlobalSecurity();
    const columnData = useMemo(() => getColumnData({organisationId: props.organisationId, accessToken: accessToken!, filter: props.filter}), [accessToken, props.filter, props.organisationId]);
    
    function getIdAccessor(delivery: Delivery): string {
        return Object.values(delivery).join(",");
    }

    return ( 
        <div>
            <TableReport  
                columns={columnData} 
                rows={props.deliveries}
                pageSize={25} 
                defaultSort={{accessor: "date", direction: "desc"}} 
                idAccessor={getIdAccessor} 
                isLoading={props.isLoading} 
                loadingText="Loading deliveries..."
                noContentMessage={"No deliveries found"}
            />
        </div>
    );
}

function getColumnData(props: {organisationId: OrganisationId, accessToken: string, filter: DashFilter}): TableReportColumn<DeliveryColumn>[] {

    const date:         TableReportColumn<DeliveryColumn> = { accessor: "date",              sortable: true,     title: "Date",       visibility: "alwaysVisible",  render: (delivery) => <>{FormatDate(delivery.date)}</> };
    const store:        TableReportColumn<DeliveryColumn> = { accessor: "inventoryName",     sortable: true,     title: "Location",   visibility: "alwaysVisible" };
    const brand:        TableReportColumn<DeliveryColumn> = { accessor: "brand",             sortable: true,     title: "Brand",      visibility: "visible",        render: (delivery) => <GetBrand brand={delivery.brand}/> };
    const articles:     TableReportColumn<DeliveryColumn> = { accessor: "articleQuantity",   sortable: true,     title: "Articles",   visibility: "visible" };
    const pieces:       TableReportColumn<DeliveryColumn> = { accessor: "piecesQuantity",    sortable: true,     title: "Pieces",     visibility: "visible" };
    const download:     TableReportColumn<DeliveryColumn> = { accessor: "download",          sortable: false,    title: "Download",   visibility: "visible", render: (delivery) => <GetDownloadButton delivery={delivery} organisationId={props.organisationId} accessToken={props.accessToken} filter={props.filter} />};

    return [
        date, store, brand, articles, pieces, download
    ];
};

interface DeliveryColumn extends Delivery {
    download?: undefined
}

function GetBrand(props: {brand: string}) {
    return <div className="relative w-full min-w-[120px]"><div className="flex items-center v-tablet:flex-col v-tablet:items-start gap-2 overflow-x-scroll no-scrollbar">
    <SimpleBadge className="h-6" >{props.brand.toUpperCase()}</SimpleBadge>
</div></div>
}

interface DownloadProps {
    delivery: Delivery; 
    organisationId: OrganisationId;
    accessToken: string;
    filter: DashFilter;
}

function GetDownloadButton(props: DownloadProps) {
    return <AsyncLinkDownload
        icon={<Download />}
        onClick={() => doDownloadDelivery(props)}
        label="Specification (.xslx)"
    />
}

function doDownloadDelivery(props: DownloadProps) {
    return downloadDeliveryBreakdown(
        props.organisationId,
        FormatDate(props.delivery.date),
        props.delivery.inventoryId,
        props.delivery.inventoryName,
        props.accessToken, 
        props.filter.toQueryString()
    );
}