import {portalApi} from "./base/PortalApi";
import {OrganisationId} from "./models/UserProfile";
import {
    ArticlePerformanceResponse,
    ArticleActivityResponse,
    ArticlePerStoreResponse,
    CategoryPerformanceResponse,
    SortDirection,
    StorePerformanceResponse,
    SeasonPerformanceResponse,
    BrandPerformanceResponse, PartnerWholesaleResponse
} from './Models';
import { DashSearchResult } from "src/pages/new/Dashboard";

export interface DailySellThroughResponse {
    day: string;
	seasonName: string; 
	itemsReceived: number;
	itemsSold: number;
}
 
export interface DailyStockAndWholesale {
    day: string;
	stockLevel: number;
	wholesaleLevel: number;
}

 
export interface DailyStockAndWholesaleResponse {
    currency: string,
    stockByDay: DailyStockAndWholesale[]
}

interface SeasonResponse {
    id: string;
    name: string;
}

export interface WholesaleResponse {
    totalAmount: number;
    currency: string;
}

export const reportApi = portalApi.injectEndpoints({
    endpoints: builder => ({
        getSellThrough: builder.query<DailySellThroughResponse[], {organisationId: OrganisationId, organisationType: string, startDate: string, endDate: string, filterQuery: string}>({
            query: params => `report/stock/GetSellThrough?organisationId=${params.organisationId}&organisationType=${params.organisationType}&startDate=${params.startDate}&endDate=${params.endDate}${filterToQueryString(params.filterQuery)}`
        }),
        getSellThroughAllSeasons: builder.query<DailySellThroughResponse[][], {organisationId: OrganisationId, organisationType: 'retailer' | 'supplier', startDate: string, endDate: string, filterQuery: string}>({
            query: params => `report/stock/GetSellThroughAllSeasons?organisationId=${params.organisationId}&organisationType=${params.organisationType}&startDate=${params.startDate}&endDate=${params.endDate}${filterToQueryString(params.filterQuery)}`
        }),
        getSellThroughByRetailer: builder.query<DailySellThroughResponse[], {organisationId: OrganisationId, organisationType: string,  startDate: string, endDate: string, filterQuery: string}>({
            query: params => `report/stock/GetSellThroughByRetailer?organisationId=${params.organisationId}&organisationType=${params.organisationType}&startDate=${params.startDate}&endDate=${params.endDate}${filterToQueryString(params.filterQuery)}`
        }),
        getSellThroughAllSeasonsByRetailer: builder.query<DailySellThroughResponse[][], {organisationId: OrganisationId, organisationType: string,  startDate: string, endDate: string, filterQuery: string}>({
            query: params => `report/stock/GetSellThroughAllSeasonsByRetailer?organisationId=${params.organisationId}&organisationType=${params.organisationType}&startDate=${params.startDate}&endDate=${params.endDate}${filterToQueryString(params.filterQuery)}`
        }),
        getSeasons: builder.query<SeasonResponse[], {organisationId: OrganisationId, supplierId: OrganisationId}>({
            query: params => `report/stock/GetSeasons?organisationId=${params.organisationId}&supplierId=${params.supplierId}`
        }), 
        getBrands: builder.query<string[], {organisationId: OrganisationId, supplierId: OrganisationId, seasonId: string}>({
            query: params => `report/stock/GetBrands?organisationId=${params.organisationId}&supplierId=${params.supplierId}&seasonId=${params.seasonId}`
        }), 
        getCategoryPerformance : builder.query<CategoryPerformanceResponse, {organisationId: OrganisationId, startDate: string, endDate: string, sortDirection: SortDirection, filterQuery: string}> ({
            query: (params) => `/report/stock/GetCategoryPerformance?organisationId=${params.organisationId}&startDate=${params.startDate}&endDate=${params.endDate}&sortDirection=${params.sortDirection}&${params.filterQuery}`
        }),
        getPartnerWholesale : builder.query<PartnerWholesaleResponse, {organisationId: OrganisationId, startDate: string, endDate: string, filterQuery: string}> ({
            query: (params) => `/report/stock/GetPartnerWholesale?organisationId=${params.organisationId}&startDate=${params.startDate}&endDate=${params.endDate}&${params.filterQuery}`
        }),
        getBrandPerformance : builder.query<BrandPerformanceResponse, {organisationId: OrganisationId, startDate: string, endDate: string, sortDirection: SortDirection, filterQuery: string}> ({
            query: (params) => `/report/stock/GetBrandPerformance?organisationId=${params.organisationId}&startDate=${params.startDate}&endDate=${params.endDate}&sortDirection=${params.sortDirection}&${params.filterQuery}`
        }),
        getArticlePerformance : builder.query<ArticlePerformanceResponse, {organisationId: OrganisationId, startDate: string, endDate: string, sortDirection: SortDirection, filterQuery: string}> ({
            query: (params) => `/report/stock/GetArticlePerformance?organisationId=${params.organisationId}&startDate=${params.startDate}&endDate=${params.endDate}&sortDirection=${params.sortDirection}&${params.filterQuery}`
        }),
        getSupplierArticleActivity : builder.query<ArticleActivityResponse, {organisationId: OrganisationId, sortDirection: SortDirection, filterQuery: string}> ({
            query: (params) => `/report/stock/GetSupplierArticleActivity?organisationId=${params.organisationId}&sortDirection=${params.sortDirection}&${params.filterQuery}`
        }),
        getStorePerformance : builder.query<StorePerformanceResponse, {organisationId: OrganisationId, startDate: string, endDate: string, sortDirection: SortDirection, filterQuery: string}> ({
            query: (params) => `/report/stock/GetStorePerformance?organisationId=${params.organisationId}&startDate=${params.startDate}&endDate=${params.endDate}&sortDirection=${params.sortDirection}&${params.filterQuery}`
        }),
        getSeasonPerformance : builder.query<SeasonPerformanceResponse, {organisationId: OrganisationId, startDate: string, endDate: string, sortDirection: SortDirection, filterQuery: string}> ({
            query: (params) => `/report/stock/GetSeasonPerformance?organisationId=${params.organisationId}&startDate=${params.startDate}&endDate=${params.endDate}&sortDirection=${params.sortDirection}&${params.filterQuery}`
        }),
        getArticlePerStore : builder.query<ArticlePerStoreResponse, {organisationId: OrganisationId, sortDirection: SortDirection, filterQuery: string}> ({
            query: (params) => `/report/stock/GetArticlePerStore?organisationId=${params.organisationId}&sortDirection=${params.sortDirection}&${params.filterQuery}`
        }),
        getDashboardSearchResult: builder.query<DashSearchResult, {organisationId: OrganisationId, searchQuery: string}>({
            query: params => `/report/search/GetDashboardSearchResult?organisationId=${params.organisationId}&searchQuery=${params.searchQuery}`
        }),
        getDeliveries: builder.query<Delivery[], {organisationId: OrganisationId, startDate: string, endDate: string, filterQuery: string}>({
            query: params => `/report/stock/GetDeliveries?organisationId=${params.organisationId}&startDate=${params.startDate}&endDate=${params.endDate}&${params.filterQuery}`
        }),
        getIndividualStocks: builder.query<IndividualStock[], {organisationId: OrganisationId, filterQuery: string}>({
            query: params => `/report/stock/GetIndividualStocks?organisationId=${params.organisationId}&${params.filterQuery}`
        }),
        getSupplierWholesale: builder.query<WholesaleResponse, {organisationId: OrganisationId, startDate: string, endDate: string, filterQuery: string}>({
            query: params => `/report/stock/GetTotalWholesale?organisationId=${params.organisationId}&startDate=${params.startDate}&endDate=${params.endDate}&${params.filterQuery}`
        }),
    })
});

function filterToQueryString(filterQuery: string) {
    return filterQuery.length === 0 ? '' : '&' + filterQuery;
}

export const {
    useGetSellThroughAllSeasonsByRetailerQuery,
    useGetSeasonsQuery,
    useGetBrandsQuery,
    useGetPartnerWholesaleQuery,
    useGetCategoryPerformanceQuery,
    useGetBrandPerformanceQuery,
    useGetSupplierArticleActivityQuery,
    useGetStorePerformanceQuery,
    useGetSeasonPerformanceQuery,
    useGetArticlePerformanceQuery,
    useGetArticlePerStoreQuery,
    useGetDashboardSearchResultQuery,
    useGetDeliveriesQuery,
    useGetSupplierWholesaleQuery,
    useGetIndividualStocksQuery
} = reportApi;

export interface Delivery {
    date: string,
    inventoryId: OrganisationId,
    inventoryName: string,
    brand: string,
    articleQuantity: number,
    piecesQuantity: number
}

export interface IndividualStock {
    ean: string,
    articleNumber: string,
    brand: string,
    description: string,
    category?: string,
    color?: string,
    colorCode?: string,
    size?: string,
    gender?: string,
    seasonId: string,
    seasonName: string,
    wholesalePrice: number,
    currency: string,
    inventoryId: OrganisationId,
    inventoryName: string,
    stock: number,
    lastSale: string,
    lastDelivery: string,
    itemsReceived: number,
    itemsSold: number,
    imported: string,
    sellThrough?:number,
    imageUrl?: string
}